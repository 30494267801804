import { Flex } from '@pancakeswap/uikit'
import { PropsWithChildren, memo } from 'react'
import styled from 'styled-components'

export const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  gap: 1rem;
`

export const FormContainer = memo(function FormContainer({ children }: PropsWithChildren) {
  return (
    <Wrapper id="swap-page">
      {children}
    </Wrapper>
  )
})
