import { TradeType } from '@pancakeswap/sdk'
import { SmartRouter, SmartRouterTrade } from '@pancakeswap/smart-router/evm'
import { AutoColumn, ChevronDownIcon, ChevronUpIcon, RowBetween, RowFixed, Text } from '@pancakeswap/uikit'
import { Swap as SwapUI } from '@pancakeswap/widgets-internal'
import useLastTruthy from 'hooks/useLast'
import { useMemo, memo, useState } from 'react'

import { useTranslation } from '@pancakeswap/localization'
import { AdvancedSwapDetails, TradeSummary } from 'views/Swap/components/AdvancedSwapDetails'
import { AdvancedDetailsFooter } from 'views/Swap/components/AdvancedSwapDetailsDropdown'
import { formatPrice } from '@pancakeswap/utils/formatFractions'

import { MMTradeInfo } from 'views/Swap/MMLinkPools/hooks'
import { RoutesBreakdown } from '../components'
import { useSlippageAdjustedAmounts, useIsWrapping } from '../hooks'
import { computeTradePriceBreakdown } from '../utils/exchange'

interface Props {
  loaded: boolean
  trade?: SmartRouterTrade<TradeType> | null
}

export function MMTradeDetail({ loaded, mmTrade }: { loaded: boolean; mmTrade?: MMTradeInfo }) {
  const lastTrade = useLastTruthy(mmTrade?.trade)

  return (
    <AdvancedDetailsFooter show={loaded}>
      <AutoColumn gap="0px">
        {lastTrade && (
          <AdvancedSwapDetails
            pairs={[]}
            path={lastTrade?.routes[0].path}
            slippageAdjustedAmounts={mmTrade?.slippageAdjustedAmounts}
            realizedLPFee={mmTrade?.realizedLPFee}
            inputAmount={mmTrade?.inputAmount}
            outputAmount={mmTrade?.outputAmount}
            tradeType={mmTrade?.tradeType}
            priceImpactWithoutFee={mmTrade?.priceImpactWithoutFee}
            isMM
          />
        )}
      </AutoColumn>
    </AdvancedDetailsFooter>
  )
}

export const TradeDetails = memo(function TradeDetails({ loaded, trade }: Props) {
  const { t } = useTranslation()

  const [showMore, setMore] = useState(false)
  const slippageAdjustedAmounts = useSlippageAdjustedAmounts(trade)
  const isWrapping = useIsWrapping()
  const { priceImpactWithoutFee, lpFeeAmount } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const price = useMemo(() => SmartRouter.getExecutionPrice(trade), [trade])
  const hasStablePool = useMemo(
    () => trade?.routes.some((route) => route.pools.some(SmartRouter.isStablePool)),
    [trade],
  )

  if (isWrapping || !loaded || !trade) {
    return null
  }

  const { inputAmount, outputAmount, tradeType, routes } = trade

  const formattedPrice = formatPrice(price, 6)

  // if(!loaded)
  //   return <></>

  return (
    <>
      <RowBetween onClick={() => setMore(!showMore)}>
        <Text>
          1 {`${price?.baseCurrency?.symbol}`}
          {` = `}
          {`${formattedPrice} ${price?.quoteCurrency?.symbol}`}
        </Text>
        {showMore ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </RowBetween>
      {
        showMore &&
        <AdvancedDetailsFooter show={true}>
          <AutoColumn gap="0px">
            <RowBetween style={{ padding: '0 24px' }}>
              <RowFixed>
                <Text fontSize="14px" color="textSubtle">
                  {t('Price')}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontSize="14px">
                  <SwapUI.TradePrice price={price} loading={!loaded} />
                </Text>
              </RowFixed>
            </RowBetween>
            <TradeSummary
              slippageAdjustedAmounts={slippageAdjustedAmounts}
              inputAmount={inputAmount}
              outputAmount={outputAmount}
              tradeType={tradeType}
              priceImpactWithoutFee={priceImpactWithoutFee}
              realizedLPFee={lpFeeAmount}
              hasStablePair={hasStablePool}
            />
            <RoutesBreakdown routes={routes} />
          </AutoColumn>
        </AdvancedDetailsFooter>
      }
    </>
  )
})
